import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { coursePageQuizReviewBdi4Ca } from 'dsoapi';

const CoursePageQuizReviewBdi4Ca = ({ courseData, setPageValid }) => {
  const { canReAttempt, passed, score, passingScore, quiz } =
    courseData.quizReview;

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageValid(() => async () => {
      const response = await coursePageQuizReviewBdi4Ca(courseData.hashLink);
      if (response.data.nextHashLink) {
        navigate(`/courseapp/${response.data.nextHashLink}`);
        return false;
      } else {
        return true;
      }
    });
  }, [courseData.hashLink, setPageValid, navigate]);

  let quizResultMarkup = '';
  if (passed) {
    quizResultMarkup = (
      <>
        {`Congratulations! You passed with a score of ${score}%`} <br /> Please
        click Next below!
      </>
    );
  }
  if (!passed && canReAttempt) {
    quizResultMarkup = (
      <>
        {`You scored a ${score}% on attempt 1 of 2`}
        <br />{' '}
        {`The state requires a passing score of ${passingScore}% or more`}
        <br /> Please click Next below to attempt a new exam
      </>
    );
  }
  if (!passed && !canReAttempt) {
    quizResultMarkup = (
      <>
        {`You scored a ${score}% on attempt 2 of 2`} <br />
        {`The state requires a passing score of ${passingScore}% or more`}{' '}
        <br />
        Due to state guidelines after a second failed attempt you can re-enroll
        in California Traffic Violator School (with any provider) if court
        continuance time allows.
        <br />
        Please click the next button below if you would like to re-enroll <br />
        in TVS with Driving School of America.
      </>
    );
  }

  const renderQuestions = () => {
    return quiz.questions.map((question, i) => {
      return (
        <div className="grouped fields" key={i}>
          <label>{question.question.questionText}</label>
          {question.question.imagePath && (
            <>
              <br />
              <img
                src={question.question.imagePath}
                alt={`question ${i}`}
                className="ui fluid image"
                style={{ maxWidth: '400px' }}
              />
            </>
          )}
          {renderAnswers(question.answers, question.question.selectedAnswerId)}
          <br />
          <p style={{ fontStyle: 'italic' }}>
            <i
              className="thumbs up outline icon"
              style={{ color: '#0281B1' }}
            />{' '}
            {question.question.answerText}
          </p>
          <br />
        </div>
      );
    });
  };

  const renderAnswers = (answers, studentAnswer) => {
    return answers.map((answer) => {
      return (
        <div className="field" key={answer.questionAnswerId}>
          <p>
            {answer.correct && (
              <i className="check icon" style={{ color: 'green' }} />
            )}
            {studentAnswer === answer.questionAnswerId && (
              <i className="close icon" style={{ color: 'red' }} />
            )}
            {answer.answerText}
          </p>
        </div>
      );
    });
  };

  return (
    <>
      <h3 className="ui center aligned header">Exam Review</h3>
      <div className="ui divider"></div>
      <h3 className="ui center aligned header">{quizResultMarkup}</h3>
      {quiz.questions && (
        <>
          <h4 className="ui dividing header">Incorrect Questions</h4>
          <div className="ui form">{renderQuestions()}</div>
        </>
      )}
      <br />
    </>
  );
};

export default CoursePageQuizReviewBdi4Ca;
