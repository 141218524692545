import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { coursePageAddBdi4CaEvaluation } from 'dsoapi';

const s3Options = [
  { text: '10', value: '10' },
  { text: '9', value: '9' },
  { text: '8', value: '8' },
  { text: '7', value: '7' },
  { text: '6', value: '6' },
  { text: '5', value: '5' },
  { text: '4', value: '4' },
  { text: '3', value: '3' },
  { text: '2', value: '2' },
  { text: '1', value: '1' },
];

const s5Options = [
  { text: 'YES', value: '1' },
  { text: 'NO', value: '2' },
  { text: 'N/A', value: '3' },
];

const defaultAnswers = {
  s3Q1Score: null,
  s3Q1Reason: null,
  s3Q2Score: null,
  s3Q2Reason: null,
  s3Q3Score: null,
  s3Q3Reason: null,
  s3Q4Score: null,
  s3Q4Reason: null,
  s3Q5Score: null,
  s3Q5Reason: null,
  s3Q6Score: null,
  s3Q6Reason: null,
  s3Q7Score: null,
  s3Q7Reason: null,
  s5Q1Answer: null,
  s5Q2Answer: null,
  s5Q3Answer: null,
  s5Q4Answer: null,
  s5Q5Answer: null,
  s5Q6Answer: null,
  s5Q7Answer: null,
  s5Q8Answer: null,
};

const CoursePageCourseEvaluationCa = ({ courseData, setPageValid }) => {
  const [answers, setAnswers] = useState(defaultAnswers);

  useEffect(() => {
    setPageValid(() => async () => {
      await coursePageAddBdi4CaEvaluation(courseData.hashLink, answers);
      return true;
    });
  }, [answers, courseData.hashLink, setPageValid]);

  return (
    <div className="ui container">
      <h3 className="ui center aligned header">
        TRAFFIC VIOLATOR COURSE EVALUATION
      </h3>
      This completed course evaluation will provide the traffic school and DMV
      valuable feedback from your perspective. Submitting this form is a
      requirement for completing the course, however your answers remain
      voluntary. To satisfy this requirement you will need to provide your name
      and school information this will document that you have met the course
      evaluation requirement. If you choose to not participate in the
      evaluation, simply skip them by going straight to the Submit button at the
      bottom. Any information provided on this form, either positive or
      negative, will not affect the status of your driving record or credit
      received for completion of this class.
      <br />
      <br />
      Once finished, press the submit button below to complete the course.
      <br />
      <br />
      <div className="ui form">
        <div className="ui grid">
          <div className="row">
            <div className="sixteen wide column">
              Answer questions 1 - 7 using a scale of 1 - 10, 10 for excellent
              and 1 for poor.{' '}
              <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                Please explain all questions scored 5 or less.
              </span>
            </div>
          </div>
          <div className="ui divider" />
          <div className="row">
            <div className="twelve wide column">
              1. I had no difficulty registering for this course.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s3q1"
                fluid
                selection
                options={s3Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s3Q1Score: value } })
                }
              />
            </div>
          </div>
          {answers.s3Q1Score && answers.s3Q1Score < 6 && (
            <div className="row">
              <div className="sixteen wide column">
                <textarea
                  rows="1"
                  placeholder="Please explain"
                  style={{ resize: 'none' }}
                  maxLength="250"
                  onChange={(e) =>
                    setAnswers({
                      ...answers,
                      ...{ s3Q1Reason: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="twelve wide column">
              2. The course instruction was easily understood and to the point.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s3q2"
                fluid
                selection
                options={s3Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s3Q2Score: value } })
                }
              />
            </div>
          </div>
          {answers.s3Q2Score && answers.s3Q2Score < 6 && (
            <div className="row">
              <div className="sixteen wide column">
                <textarea
                  rows="1"
                  placeholder="Please explain"
                  style={{ resize: 'none' }}
                  maxLength="250"
                  onChange={(e) =>
                    setAnswers({
                      ...answers,
                      ...{ s3Q2Reason: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="twelve wide column">
              3. I learned something of value that can help me avoid future
              violations.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s3q3"
                fluid
                selection
                options={s3Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s3Q3Score: value } })
                }
              />
            </div>
          </div>
          {answers.s3Q3Score && answers.s3Q3Score < 6 && (
            <div className="row">
              <div className="sixteen wide column">
                <textarea
                  rows="1"
                  placeholder="Please explain"
                  style={{ resize: 'none' }}
                  maxLength="250"
                  onChange={(e) =>
                    setAnswers({
                      ...answers,
                      ...{ s3Q3Reason: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="twelve wide column">
              4. I received everything the school promised when I registered.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s3q4"
                fluid
                selection
                options={s3Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s3Q4Score: value } })
                }
              />
            </div>
          </div>
          {answers.s3Q4Score && answers.s3Q4Score < 6 && (
            <div className="row">
              <div className="sixteen wide column">
                <textarea
                  rows="1"
                  placeholder="Please explain"
                  style={{ resize: 'none' }}
                  maxLength="250"
                  onChange={(e) =>
                    setAnswers({
                      ...answers,
                      ...{ s3Q4Reason: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="twelve wide column">
              5. I was provided the correct answer and reasoning for that answer
              on the final exam for questions I missed.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s3q5"
                fluid
                selection
                options={s3Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s3Q5Score: value } })
                }
              />
            </div>
          </div>
          {answers.s3Q5Score && answers.s3Q5Score < 6 && (
            <div className="row">
              <div className="sixteen wide column">
                <textarea
                  rows="1"
                  placeholder="Please explain"
                  style={{ resize: 'none' }}
                  maxLength="250"
                  onChange={(e) =>
                    setAnswers({
                      ...answers,
                      ...{ s3Q5Reason: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="twelve wide column">
              6. The instructor provided his/her name and occupational license
              number.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s3q6"
                fluid
                selection
                options={s3Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s3Q6Score: value } })
                }
              />
            </div>
          </div>
          {answers.s3Q6Score && answers.s3Q6Score < 6 && (
            <div className="row">
              <div className="sixteen wide column">
                <textarea
                  rows="1"
                  placeholder="Please explain"
                  style={{ resize: 'none' }}
                  maxLength="250"
                  onChange={(e) =>
                    setAnswers({
                      ...answers,
                      ...{ s3Q6Reason: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="twelve wide column">
              7. There were no advertisements or unrelated material displayed or
              discussed during the course or final examination.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s3q7"
                fluid
                selection
                options={s3Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s3Q7Score: value } })
                }
              />
            </div>
          </div>
          {answers.s3Q7Score && answers.s3Q7Score < 6 && (
            <div className="row">
              <div className="sixteen wide column">
                <textarea
                  rows="1"
                  placeholder="Please explain"
                  style={{ resize: 'none' }}
                  maxLength="250"
                  onChange={(e) =>
                    setAnswers({
                      ...answers,
                      ...{ s3Q7Reason: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="ui divider" />
          <div className="row">
            <div className="twelve wide column">
              1. I contacted the school regarding a course question (with a
              “YES” answer complete 2-8; a “NO” answer continue to 6-8)
            </div>
            <div className="four wide column">
              <Dropdown
                name="s5q1"
                fluid
                selection
                options={s5Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s5Q1Answer: value } })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="twelve wide column">
              2. I was able to speak with an instructor.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s5q2"
                fluid
                selection
                options={s5Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s5Q2Answer: value } })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="twelve wide column">
              3. A satisfactory answer/solution was provided within one business
              day.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s5q3"
                fluid
                selection
                options={s5Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s5Q3Answer: value } })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="twelve wide column">
              4. The instructor was courteous and professional.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s5q4"
                fluid
                selection
                options={s5Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s5Q4Answer: value } })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="twelve wide column">
              5. The instructor provided his/her name and occupational license
              number.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s5q5"
                fluid
                selection
                options={s5Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s5Q5Answer: value } })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="twelve wide column">
              6. The instructor provided his/her name and occupational license
              number.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s5q6"
                fluid
                selection
                options={s5Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s5Q6Answer: value } })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="twelve wide column">
              7. I received a response to my initial course enrollment inquiry
              within 5 business days.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s5q7"
                fluid
                selection
                options={s5Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s5Q7Answer: value } })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="twelve wide column">
              8. I was able to browse through the website easily.
            </div>
            <div className="four wide column">
              <Dropdown
                name="s5q8"
                fluid
                selection
                options={s5Options}
                onChange={(_, { value }) =>
                  setAnswers({ ...answers, ...{ s5Q8Answer: value } })
                }
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div>
          To file a complaint regarding this school or instructor go to
          www.dmv.ca.gov/forms. Complete the Driving School and Traffic Violator
          Complaint form, (OL 620) and return it to the address on the form.
        </div>
      </div>
    </div>
  );
};
export default CoursePageCourseEvaluationCa;
