import React from 'react';
import { Link } from 'react-router-dom';

const TableOfContents = ({ rows }) => {
  const renderToc = () => {
    return rows.map((row, i) => {
      return (
        <div key={i}>
          {row.hashLink && (
            <Link
              className={`${row.isCurrentPage ? 'active ' : ''}item`}
              to={`/courseapp/${row.hashLink}`}
            >
              {row.title}
              <i className={icon(row.coursePageType)}></i>
            </Link>
          )}
          {!row.hashLink && (
            <div className="item" style={{ color: 'gray' }}>
              {row.title}
              <i className={icon(row.coursePageType)}></i>
            </div>
          )}
        </div>
      );
    });
  };

  const icon = (coursePageType) => {
    switch (coursePageType) {
      case 'Text':
        return 'file alternate outline icon';
      case 'Video':
        return 'video icon';
      case 'Quiz':
        return 'pencil alternate icon';
      case 'QuizReviewBdi4Ca':
        return 'clipboard outline icon';
      case 'CourseEvaluationCa':
        return 'edit outline icon';
      default:
        return 'file alternate outline icon';
    }
  };

  return <>{rows && renderToc()}</>;
};

export default TableOfContents;
