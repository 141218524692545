import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GatherSecurityQuestions from './GatherSecurityQuestions';
import GatherInfoBDI from './GatherInfoBDI';
import GatherInfoBDI4CA from './GatherInfoBDI4CA';
import GatherInfoBDI4TN from './GatherInfoBDI4TN';
import GatherInfoBDI8TN from './GatherInfoBDI8TN';
import GatherInfoDDC6NJ from './GatherInfoDDC6NJ';
import GatherInfoSDDANJ from './GatherInfoSDDANJ';
import GatherInfoTLSAE from './GatherInfoTLSAE';
import GatherStudentInfo from './GatherStudentInfo';
import { courseFromEnrollment } from 'dsoapi';
import RegistrationSteps from '../RegistrationSteps';

const GatherCourseInfo = ({ showRegistrationSteps, setTitle = () => {} }) => {
  const [courseDescription, setCourseDescription] = useState('');
  const { infoPage, uceId } = useParams();

  setTitle('Enter Course Information');

  useEffect(() => {
    const fetchData = async () => {
      const courseResp = await courseFromEnrollment(uceId);
      setCourseDescription(courseResp.data.displayName);
    };
    fetchData();
  }, [uceId]);

  const getPage = () => {
    switch (infoPage) {
      case 'StudentInfo':
        return (
          <GatherStudentInfo
            uceId={uceId}
            courseDescription={courseDescription}
            showRegistrationSteps={showRegistrationSteps}
          />
        );
      case 'SecurityQuestions':
        return (
          <GatherSecurityQuestions
            uceId={uceId}
            courseDescription={courseDescription}
            showRegistrationSteps={showRegistrationSteps}
          />
        );
      case 'InfoBDI':
        return (
          <GatherInfoBDI uceId={uceId} courseDescription={courseDescription} />
        );
      case 'InfoBDI4CA':
        return (
          <GatherInfoBDI4CA
            uceId={uceId}
            courseDescription={courseDescription}
          />
        );
      case 'InfoBDI4TN':
        return (
          <GatherInfoBDI4TN
            uceId={uceId}
            courseDescription={courseDescription}
          />
        );
      case 'InfoBDI8TN':
        return (
          <GatherInfoBDI8TN
            uceId={uceId}
            courseDescription={courseDescription}
          />
        );
      case 'InfoDDC6NJ':
        return (
          <GatherInfoDDC6NJ
            uceId={uceId}
            courseDescription={courseDescription}
          />
        );
      case 'InfoSDDANJ':
        return (
          <GatherInfoSDDANJ
            uceId={uceId}
            courseDescription={courseDescription}
          />
        );
      case 'InfoTLSAE':
        return (
          <GatherInfoTLSAE
            uceId={uceId}
            courseDescription={courseDescription}
          />
        );
      default:
        return (
          <>
            <p>{`Unknown page type ${infoPage}`}</p>
          </>
        );
    }
  };

  return (
    <>
      <div className="ui center aligned container">
        {showRegistrationSteps && <RegistrationSteps step={2} />}
        <h3>{courseDescription}</h3>
        <div style={{ height: '15px' }}></div>
      </div>
      {getPage()}
    </>
  );
};

export default GatherCourseInfo;
